<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Send Gift</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading || ui.autoFill">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="form-group full-width">
              <label for="fieldBorrower"> Borrower </label>
              <multiselect
                v-model="formData.borrower"
                placeholder="Select from recently funded loans"
                id="fieldBorrower"
                class="full-width"
                :options="borrowers"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
                label="name"
                track-by="id"
                @select="getAutoFillAddress"
              >
              </multiselect>
            </div>
            <div class="form-group full-width mt-4">
              <label for="fieldGift"> Gift </label>
              <multiselect
                v-model="formData.gift"
                placeholder="Select gift"
                id="fieldGift"
                class="full-width"
                :options="gifts"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                :show-labels="false"
                label="name"
                track-by="id"
                @select="selectGift"
              >
              </multiselect>
            </div>

            <span
              class="error-msg"
              v-for="(error, index) in getError('gift', errors)"
              :key="index"
            >
              {{ error }}
            </span>
            <div v-if="formData.gift" class="form-group full-width mt-3">
              <div class="text-center" v-if="ui.preview">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <img v-else-if="!ui.isInstock" :src="selectedGiftImage" class="img-fluid rounded img-thumbnail" alt="">
            </div>
          </div>
          <div class="modal-body-container mt-4">
            <div class="modal-body-container_title">Delivery Address</div>
            <div class="form-group full-width">
              <label for="fieldStreet"> Street Address </label>
              <input
                type="text"
                class="form-control"
                id="fieldStreet"
                v-model="formData.street"
                :class="{ 'input-filled': formData.street.length }"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('streetAddress', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="row mt-4">
              <div class="col-4">
                <div class="form-group full-width">
                  <label for="fieldCity"> City </label>
                  <input
                    type="text"
                    class="form-control"
                    id="fieldCity"
                    v-model="formData.city"
                    :class="{ 'input-filled': formData.city.length }"
                  />
                  <span
                    class="error-msg"
                    v-for="(error, index) in getError('city', errors)"
                    :key="index"
                  >
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group full-width">
                  <label for="fieldState"> State </label>
                  <multiselect
                    v-model="formData.state"
                    placeholder="Select state"
                    id="fieldState"
                    class="full-width"
                    :options="states"
                    :searchable="false"
                    :close-on-select="true"
                    :allow-empty="false"
                    :show-labels="false"
                    label="label"
                    track-by="value"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group full-width">
                  <label for="fieldZipCode"> Zip Code </label>
                  <input
                    type="text"
                    class="form-control"
                    id="fieldZipCode"
                    v-model="formData.zipCode"
                    :class="{ 'input-filled': formData.zipCode.length }"
                  />
                  <span
                    class="error-msg"
                    v-for="(error, index) in getError('zipCode', errors)"
                    :key="index"
                  >
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer d-flex justify-content-start"
        v-if="!ui.loading || !ui.autoFill"
      >
        <base-button title="Order" action="secondary-default" @click-btn="saveGift" :disabled="!hideSubmit" :loading="ui.saving" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "SendGift",
  components: { Multiselect, BaseButton },
  data() {
    return {
      borrowers: [],
      gifts: [],
      states: [],
      errors: [],
      selectedGiftImage: null,
      formData: {
        gift: "",
        borrower: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
      },
      ui: {
        loading: false,
        saving: false,
        autoFill: false,
        preview: false,
        isInstock: false
      }
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
      .get("/api/v1/gift/order/new")
      .then((res) => {
        this.borrowers = res.data.borrowers;
        this.gifts = res.data.gifts;
        this.states = res.data.states;
        this.formData.state = res.data.states[1];
        if (res.data.borrowers.length) {
          this.getAutoFillAddress(res.data.borrowers[0]);
          this.formData.borrower = res.data.borrowers[0];
        }
      })
      .catch((err) => {
        this.alertError(err.response.statusText);
      })
      .finally(() => {
        this.ui.loading = false;
      });
    },
    saveGift() {
      this.ui.saving = true;
      this.errors = [];

      let formData = new FormData();
      formData.append("borrower", this.formData.borrower.id);
      formData.append("gift", this.formData.gift.id);
      formData.append("street", this.formData.street);
      formData.append("city", this.formData.city);
      formData.append("state", this.formData.state.value);
      formData.append("zipCode", this.formData.zipCode);

      this.$http
        .post("/api/v1/gift/order/new", formData)
        .then((res) => {
          this.alertSuccess("Order has been created successfully!");
          this.$emit("success", res.data);
          this.$emit("close");
        })
        .catch((err) => {
          if (err.response.data.errors || err.response.status === 400) {
            this.errors = err.response.data.errors;
            this.alertError(err.response.data.message);
            this.ui.isGiftStock = this.getError('gift', this.errors);
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => {
          this.ui.saving = false;
        });
    },
    getAutoFillAddress(borrower) {
      this.ui.autoFill = true;
      this.$http
        .get(`/api/v1/gift/order/${borrower.id}/autofill`)
        .then((res) => {
          if (Object.keys(res.data).length > 0) {
            const borrower = res.data.borrower;
            this.formData.street = borrower.address;
            this.formData.city = borrower.city;

            this.states.forEach((state) => {
              if (borrower.state === state.value) {
                this.formData.state = state;
              }
            });
            this.formData.zipCode = borrower.zip;
          } else {
            this.formData.street = "";
            this.formData.city = "";
            this.formData.state = this.states[1];
            this.formData.zipCode = "";
          }
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.autoFill = false;
        });
    },
    selectGift(evt) {
      this.ui.preview = true
      this.ui.isInstock = false
      this.errors = [];
      this.$http
        .get(`/public-file/gift/${evt.id}`, {responseType: 'arraybuffer'})
        .then((res) => {
          const blob = new Blob([res.data]);
          this.selectedGiftImage = window.URL.createObjectURL(blob);
        })
        .catch ((err) => {
          if (err.response.status === 400) {
            this.ui.isInstock = true;
            this.errors = [{'path' : "gift", 'message' : "Selected gift is out of stock.", 'label' : "Gift"}];
            this.alertError('Gift is out of stock!');
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => {
          this.ui.preview = false
        })
    }
  },
  computed: {
    hideSubmit() {
      return (this.formData.gift && this.formData.borrower) || this.ui.submit;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {
  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all 0.3s;
    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
