<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Borrower Gift</h3>
        <span class="subtitle"> Borrowers eligible for gift </span>
      </div>
      <base-button title="Send gift" action="primary" @click-btn="modals.sendGift = true" v-if="tabName === 'pending'" />
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ active: tabName === 'pending' }" @click="setTab('pending')">
          Pending
        </li>
        <li :class="{ active: tabName === 'sent' }" @click="setTab('sent')">
          Sent
        </li>
      </ul>
    </div>

    <div class="controls-panel" v-if="tabName === 'pending'">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 col-md-2 border-right-light pe-4 ps-3">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-control p-0"
                    content="Select"
                    @click="selectAllGift"
                    v-tippy="{
                      placement: 'bottom',
                      arrow: false,
                      arrowType: 'round',
                    }"
                  >
                    <div class="form-group">
                      <input
                        type="checkbox"
                        name=""
                        class="form-check-input m-0"
                        v-model="selectAll"
                      />
                    </div>
                  </button>
                </div>
                <div class="col-6"
                  v-if=" isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_BORROWER_GIFT_MANAGER')">
                  <button
                    class="btn btn-control"
                    content="Delete"
                    @click="deleteSelected"
                    v-tippy="{
                      placement: 'bottom',
                      arrow: false,
                      arrowType: 'round',
                    }"
                  >
                    <img src="@/assets/icons/bold-icon delete.svg" alt="Delete"/>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-md-4"
              v-if="
                isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_BORROWER_GIFT_MANAGER')
              "
            >
            <base-button title="Mark all as sent" action="secondary" size="sm"
                         @click-btn="markAll" :disabled="!showMarkAll || ui.markAll" :loading="ui.markAll" />
            </div>
          </div>
        </div>
        <div
          class="col-md-6 text-end"
          v-if="isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_BORROWER_GIFT_MANAGER')"
        >
          <div class="d-flex justify-content-end">
            <base-button title="Export to CSV" action="secondary-default" size="sm" @click-btn="exportAll" :loading="ui.export" :disabled="ui.export" />
          </div>
        </div>
      </div>
    </div>
    <div class="controls-panel" v-else>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-3 col-md-1 border-right-light pe-4 ps-3">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-control p-0"
                    content="Select"
                    @click="selectAllGift"
                    v-tippy="{
                      placement: 'bottom',
                      arrow: false,
                      arrowType: 'round',
                    }"
                  >
                    <div class="form-group">
                      <input
                        type="checkbox"
                        name=""
                        class="form-check-input m-0"
                        v-model="selectAll"
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-9 col-md-3">
              <base-button title="Rollback" action="secondary" size="sm"
                           @click-btn="markAll" :disabled="!showMarkAll || ui.markAll" :loading="ui.markAll" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div v-if="tabName === 'pending'">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table" v-else>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>Agent</th>
                <th>Borrower</th>
                <th>Address</th>
                <th>Client Type</th>
                <th>Status</th>
                <th>Gift</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="gifts.length === 0">
                <td colspan="8">
                  <div
                    class="col-12 modal-alert modal-alert_advice text-center"
                  >
                    No Results Found
                  </div>
                </td>
              </tr>
              <tr v-for="gift in gifts" :key="gift.id">
                <td>
                  <div class="form-group">
                    <input
                      type="checkbox"
                      class="form-check-input m-0"
                      v-model="gift.checked"
                    />
                  </div>
                </td>
                <td>{{ gift.agent.name }}</td>
                <td>{{ gift.borrower.name }}</td>
                <td>{{ prepareAddress(gift) }}</td>
                <td>{{ gift.clientType }}</td>
                <td>{{ gift.status }}</td>
                <td>{{ gift.gift.name }} (${{ gift.gift.price }})</td>
                <td>
                  <div class="btn-modal" :name="'agent' + gift.id">
                    <img
                      src="@/assets/icons/icon-more.svg"
                      alt="More button"
                      class="icon-more"
                    />
                  </div>
                  <tippy
                    boundary="window"
                    interactive="true"
                    trigger="click"
                    class="table-menu"
                    zIndex="1039"
                    animateFill="false"
                    placement="bottom-end"
                    theme="light"
                    :to="'agent' + gift.id"
                  >
                    <div class="table-menu-block">
                      <ul>
                        <li class="action-label">Actions</li>
                        <li class="cursor-pointer" v-if="gift.permission.mark">
                          <a href="#" @click="markSent(gift.id)">
                            <img
                              src="@/assets/icons/icon-inactive.svg"
                              alt=""
                            />
                            Make as Sent
                          </a>
                        </li>
                        <li
                          class="cursor-pointer"
                          v-if="gift.permission.delete"
                        >
                          <a href="#" @click="deleteOrder(gift.id)">
                            <img src="@/assets/icons/icon-delete.svg" alt="" />
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </tippy>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table" v-else>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>Agent</th>
                <th>Borrower</th>
                <th>Address</th>
                <th>Status</th>
                <th>Gift</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="gifts.length === 0">
                <td colspan="7">
                  <div
                    class="col-12 modal-alert modal-alert_advice text-center"
                  >
                    No Results Found
                  </div>
                </td>
              </tr>
              <tr v-for="gift in gifts" :key="gift.id">
                <td>
                  <div class="form-group">
                    <input
                      type="checkbox"
                      class="form-check-input m-0"
                      v-model="gift.checked"
                    />
                  </div>
                </td>
                <td>{{ gift.agent.name }}</td>
                <td>{{ gift.borrower.name }}</td>
                <td>{{ prepareAddress(gift) }}</td>
                <td>{{ gift.status }}</td>
                <td>{{ gift.gift.name }} (${{ gift.gift.price }})</td>
                <td v-if="gift.permission.rollback">
                  <button
                    class="btn btn-outline-dark"
                    @click="rollbackOrder(gift.id)"
                  >
                    Rollback
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <send-gift
        v-if="modals.sendGift === true"
        @close="modals.sendGift = false"
        @success="successOrder"
      />
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
import SendGift from "./modals/SendGift";

export default {
  name: "BorrowerGift",
  components: { SendGift, BaseButton },
  data() {
    return {
      ui: {
        markAll: false,
        loading: false,
        export: false,
      },
      modals: {
        sendGift: false,
      },
      selectAll: false,
      gifts: [],
      tabName: "pending"
    };
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/gift/orders/${this.tabName.toLowerCase()}`)
        .then((res) => {
          this.gifts = res.data;
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    selectAllGift() {
      if (this.selectAll === false) {
        this.gifts.map((el) => {
          el.checked = true;
        });
      } else {
        this.gifts.map((el) => {
          el.checked = false;
        });
      }
    },
    markSent(id) {
      this.$http
        .get(`/api/v1/gift/order/mark/${id}/sent`)
        .then(() => this.getData())
        .catch((err) => {
          this.alertError(err.response.statusText);
        });
    },
    deleteOrder(id) {
      if (confirm("Are you sure!")) {
        this.$http
          .delete(`/api/v1/gift/order/delete/${id}`)
          .then(() => this.getData())
          .catch((err) => {
            this.alertError(err.response.statusText);
          });
      }
    },
    rollbackOrder(id) {
      if (confirm("Are you sure!")) {
        this.$http
          .get(`/api/v1/gift/order/rollback/${id}`)
          .then(() => this.getData())
          .catch((err) => {
            this.alertError(err.response.statusText);
          });
      }
    },
    markAll() {
      this.ui.markAll = true;
      let formData = new FormData();
      this.gifts.forEach(function (value) {
        if (value.checked) {
          formData.append("ids[]", value.id);
        }
      });

      let url = "/api/v1/gift/order/mark-all/sent";
      if (this.tabName == "sent") {
        url = "/api/v1/gift/order/rollback-all";
      }

      this.$http
        .post(url, formData)
        .then(() => this.getData())
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.markAll = false;
        });
    },
    exportAll() {
      this.ui.export = true;
      this.$http
        .get(`/api/v1/gift/order/export`)
        .then((res) => {
          if (res.data) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let fileName = `gift_orders_${this.formatDate(
              "",
              "MM_DD_YYYY"
            )}.csv`;

            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          }
        })
        .catch((err) => {
          this.alertError(err.response.statusText);
        })
        .finally(() => (this.ui.export = false));
    },
    deleteSelected() {
      const deleteGifts = this.gifts.filter((request) => {
        return request.checked === true;
      });

      if (deleteGifts.length) {
        if (confirm("Are you sure!")) {
          let formData = new FormData();
          deleteGifts.forEach(function (value) {
            formData.append("ids[]", value.id);
          });

          this.$http
            .post("/api/v1/gift/order/delete-all", formData)
            .then(() => this.getData())
            .catch((err) => {
              this.alertError(err.response.statusText);
            });
        }
      } else {
        alert("Select gifts");
      }
    },
    setTab(val) {
      this.ui.loading = true;
      this.tabName = val;
      this.selectAll = false;
      this.getData();
    },
    successOrder(order) {
      this.ui.loading = true;
      this.gifts.unshift(order);
      setTimeout(() => {
        this.ui.loading = false;
      }, 800);
    },
    prepareAddress(gift) {
      let address = gift.street;
      if (address && gift.city) {
        address += ', ' + gift.city;
      }

      if (gift.state) {
        address += ', ' + gift.state;
      }

      if (gift.zip) {
        address += ' ' + gift.zip;
      }

      return address ? address : 'N/A';
    }
  },
  computed: {
    showMarkAll() {
      const markData = this.gifts.filter((request) => {
        return request.checked === true;
      });

      return markData.length;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
